<template>
  <suy-table :reload="loadData" v-model:columns="columns" :scroll="{ x: 1500, y: 300 }">
    <template #search>
      <a-form ref="formQueryRef" layout="inline" :model="searchForm">
        <a-form-item label="承运商名称">
          <a-input v-model:value="searchForm.name" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="onSearch">
            <template #icon>
              <SearchOutlined />
            </template>查询
          </a-button>
        </a-form-item>
      </a-form>
    </template>
    <template #toolbarRight>
      <!-- <a-button type="primary"
                @click="onclick(record,'1')">
        <template #icon>
          <PlusCircleOutlined />
        </template>
        新增
      </a-button> -->
    </template>
    <a-table :columns="[sortColumn,...columns]" :row-key="record => record.id" :data-source="listData" :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #sort="{ index }">
        {{index+1}}
      </template>

      <template #operation="{ record }">
        <span>
          <a @click="onclick(record,'4')">装车</a>
          <a-divider type="vertical" />
          <a @click="onclick(record,'3')">发车</a>
          <a-divider type="vertical" />
          <a @click="onclick(record,'3')">卸车</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确定删除?" @confirm="onclick(record,'2')">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </template>
    </a-table>
  </suy-table>
  <bigcar ref="bigcar" @onloadData="loadData" />
</template>
<script>
import { toRefs, reactive, onMounted, ref ,h} from 'vue'
import { page, carDepart } from '@/api/transport/transport'
import SuyTable from '@/components/SuyTable'
import { message } from 'ant-design-vue'
import bigcar from './bigcar'

export default {
  components: {
    SuyTable,
    bigcar
  },
  setup () {
    const bigcar = ref(null)

    const state = reactive({
      loading: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        name: ''
      },
      listData: [],
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        // {
        //   title: '运单id',
        //   dataIndex: 'id',
        //   key: 'id'
        // },
        // {
        //   title: '订单id',
        //   dataIndex: 'orderId',
        //   key: 'orderId'
        // },
        {
          title: '始发地',
          dataIndex: 'startAddress',
          key: 'startAddress'
        },

        {
          title: '收车区域',
          dataIndex: 'officeOrgName',
          key: 'officeOrgName'
        },

        {
          title: '产品服务',
          dataIndex: 'productName',
          key: 'productName'
        },
        {
          title: '推送时间',
          dataIndex: 'pushTime',
          key: 'pushTime'
        },
        {
          title: '业务员名称',
          dataIndex: 'salesman',
          key: 'salesman'
        },
        {
          title: '业务员名称',
          dataIndex: 'transportStatus.label',
          key: 'transportStatus.label'
        },
        {
          title: '运输类型',
          dataIndex: 'transportType.label',
          key: 'transportType.label'
        },
        {
          title: '车辆信息',
          dataIndex: 'orderVehicles',
          key: 'orderVehicles',
          fixed: 'right',
          width: 400,
          customRender: function (text, record, index) {
            record = ''

            for (let i = 0; i < text.text.length; i++) {
              record += '车辆品牌: ' + text.text[i].brand + '  车辆型号:' + text.text[i].model + '  车架号:' + text.text[i].vinNo
              if (i < text.text.length - 1) {
                record += ','
              }
            }
            let snArray = []
            snArray = record.split(',')
            let result = null
            for (let i = 0; i < snArray.length; i++) {
              if (i === 0) {
                result = snArray[i]
              } else {
                result = h('div', [
                      h('span',[
                        h('span',result),
                        h('br',''),
                        h('br',''),
                        h('span',snArray[i]),
                      ])
                 ])
              }
            }
            return result
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      form: {},
      getname: ''
    })
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = async () => {
      state.loading = true
      const { data, data: { records } } = await page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        name: state.searchForm.name
      })
      state.listData = records
      state.pagination.current = data.current
      state.pagination.pageSize = data.size
      state.pagination.total = data.total
      state.loading = false
    }
    onMounted(loadData)
    const onclick = (recode, type) => {
      switch (type) {
        case '1':
          message.success('开发中' + type)
          break
        case '2':
          message.success('开发中' + type)
          break
        case '3':
          carDepart({
            transportTruckId: recode.id,
            transportOrderId: recode.orderId,
            transportOrderTruckId: recode.id
          }).then((res) => {
            if (res.code === 10000) {
              message.success(' 发车成功：返回内容' + res.msg)
            } else {
              message.error(' 发车失败：返回内容' + res.msg)
            }
          })
          break
        case '4':
          bigcar.value.onClose(true)
          bigcar.value.ontransportOrderId(recode.id)
          bigcar.value.ontitle('操作')

          break

        default:
          message.success('出现错误' + type)
      }
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    return {
      bigcar,
      ...toRefs(state),
      onSearch,
      loadData,
      handleTableChange,
      onclick
    }
  }
}
</script>
