<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <a-drawer :title="title" width="90%" :closable="false" :visible="visible" @close="onClose(false)" v-if="visible">
    <div>
      <div style="margin-bottom: 16px">
        <a-button type="primary" :disabled="!hasSelected" :loading="loading" @click="onclick('','1')">
          装车
        </a-button>
        <span style="margin-left: 8px">
          <template v-if="hasSelected">
            {{ `数量： ${datakey.length} ` }}
          </template>
        </span>
        <a-button danger :disabled="!onSelected" :loading="loading" @click="onclick('','10')">
          卸车
        </a-button>
        <span style="margin-left: 8px">
          <template v-if="onSelected">
            {{ `数量： ${datakey.length} ` }}
          </template>
        </span>
      </div>

      <h4>车主车辆</h4>
      <a-table :reload="loadData" :pagination="false" :data-source="listData" :rowKey="(record,index)=>{return index}" v-model:columns="columns" :scroll="{ x: 1500, y: 300 }" :row-selection="rowSelection">
        <template #operation="{ record }">
          <span>
            <a-divider type="vertical" />
            <a @click="onclick(record,'9')">上传</a>
            <a-divider type="vertical" />
            <a-popconfirm title="确定卸车?" @confirm="onclick(record,'8')">
              <a>卸车</a>
            </a-popconfirm>
          </span>
        </template>

      </a-table>
      <a-divider type="vertical" />
      <h4>已选大版</h4>
      <a-divider type="vertical" />
      <a-table :reload="loadData" :data-source="listDataTruck" :pagination="false" :rowKey="(record,index)=>{return index}" v-model:columns="columnsTruck" :scroll="{ x: 1500}">

        <template #operation="{ record }">
          <span>
            <a-divider type="vertical" />
            <a @click="onclick(record,'4')">发车</a>
            <a-divider type="vertical" />
            <a @click="onclick(record,'5')">中途停车</a>
            <a-divider type="vertical" />
            <a @click="onclick(record,'6')">到达</a>
            <a-divider type="vertical" />
            <a @click="onclick(record,'7')">卸车</a>
            <a-divider type="vertical" />
            <a @click="onclick(record,'11')">故障</a>
          </span>
        </template>
      </a-table>
      <a-divider type="vertical" />
      <h4>可选大版</h4>
      <a-divider type="vertical" />
      <a-table :reload="loadData" :data-source="listDataDigTruck" v-model:columns="columnsDigTruck" :rowKey="(record,index)=>{return index}" :scroll="{ x: 1500}" :row-selection="rowSelectionDigTruck">

      </a-table>
    </div>
    <a-modal v-model:visible="visiblemodal" title="请填写单台运车费用" :maskClosable='false' @ok="onclick('','3')">
      <a-input v-model:value="fee" type='number' size="large" />
    </a-modal>

  </a-drawer>
  <carImg ref="carIMG" />
</template>
<script>
import { computed, reactive, ref, toRefs } from 'vue'
import { vehicledetail, truckPage, truckdetail, carPlan, carArrive, carDepart, carTransfer, carUnloadTruck, carUnloadVehicle } from '@/api/transport/transport'
import { message } from 'ant-design-vue'
import carImg from './carImgUplad'
export default {
  components: { carImg },
  computed: {
    // checkBox属性设置
    getCheckboxProps (record) {
      return record => ({
        props: {
          defaultChecked: record.name === this.data[0].name
        }
      })
    }
  },

  setup () {
    const state = reactive({
      carIMG: ref(null),
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      datakey: [],
      datakeyDigTruck: 0,
      rowSelection: {

        onChange: (datakey, selectedRows) => {
          state.datakey.length = 0
          for (let i = 0; i < selectedRows.length; i++) {
            state.datakey.push(selectedRows[i].transOrderVehicleId)
          }
        }
      },
      rowSelectionDigTruck: {
        type: 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
          state.datakeyDigTruck = 0
          state.datakeyDigTruck = selectedRows[0].id
        }
      },

      loading: false,
      transportOrderId: '',
      fee: 0,
      visible: false,
      visiblemodal: false,
      title: '车辆',
      listData: [],
      listDataDigTruck: [],
      listDataTruck: [],
      columns: [
        {
          title: '始发地',
          dataIndex: 'startAddress',
          key: 'startAddress'
        },
        {
          title: '目的地',
          dataIndex: 'endAddress',
          key: 'endAddress'
        },
        {
          title: '车架号',
          dataIndex: 'vinNo',
          key: 'vinNo'
        },
        {
          title: '车辆品牌',
          dataIndex: 'brand',
          key: 'brand'
        },
        {
          title: '车辆型号',
          dataIndex: 'model',
          key: 'model'
        },
        {
          title: '运输费用',
          dataIndex: 'transportFee',
          key: 'transportFee'
        },
        {
          title: '是否在板',
          dataIndex: 'isInTruck.label',
          key: 'isInTruck'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      columnsDigTruck: [
        {
          title: '车架号',
          dataIndex: 'carNo',
          key: 'carNo'
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          key: 'carrierName'
        },
        {
          title: '所属司机',
          dataIndex: 'driverName',
          key: 'driverName'
        },
        {
          title: '最新位置',
          dataIndex: 'latestLocation',
          key: 'latestLocation'
        },
        {
          title: '车辆型号',
          dataIndex: 'model',
          key: 'model'
        },
        {
          title: '车位',
          dataIndex: 'place',
          key: 'place'
        },
        {
          title: '车辆状态',
          dataIndex: 'truckStatus.label',
          key: 'truckStatus.label'
        },
        {
          title: '车辆类型',
          dataIndex: 'truckType.label',
          key: 'truckType.label'
        }
      ],
      columnsTruck: [
        {
          title: '运输车状态',
          dataIndex: 'truckStatus.label',
          key: 'truckStatus.label'
        },
        {
          title: '司机名',
          dataIndex: 'carrierTruckName',
          key: 'carrierTruckName'
        },
        {
          title: '线路名',
          dataIndex: 'transportLineName',
          key: 'transportLineName'
        },
        {
          title: '订单ID',
          dataIndex: 'orderId',
          key: 'orderId'
        },
        {
          title: '运单ID',
          dataIndex: 'transportOrderId',
          key: 'transportOrderId'
        },
        {
          title: '已使用车位',
          dataIndex: 'usedParkPlace',
          key: 'usedParkPlace'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          fixed: 'right',
          slots: {
            customRender: 'operation'
          }
        }
      ]
    })
    const hasSelected = computed(() => state.datakey.length > 0 & state.datakeyDigTruck.length > 0)
    const onSelected = computed(() => state.datakey.length > 0)

    const loadData = async () => {
      state.loading = true
      const p = {}
      p.queryDTO = {
        current: state.pagination.current,
        size: state.pagination.pageSize
      }
      vehicledetail(state.transportOrderId, '').then((res) => {
        state.listData = res.data
      })
      truckdetail(state.transportOrderId, '').then((res) => {
        state.listDataTruck = res.data
      })
      truckPage(p).then((res) => {
        state.listDataDigTruck = res.data.records
        state.pagination.current = res.data.records.current
        state.pagination.pageSize = res.data.records.size
        state.pagination.total = res.data.records.total
      })
      state.loading = false
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const onClose = (recode) => {
      state.visible = recode
    }
    const onclick = (recode, type) => {
      switch (type) {
        case '1':
          state.visiblemodal = true
          break
        case '2':
          state.visiblemodal = false
          break
        case '3':
          carPlan({
            carrierTruckId: state.datakeyDigTruck,
            customerVehicleIds: state.datakey,
            transportOrderId: state.transportOrderId,
            fee: state.fee
          }).then((res) => {
            if (res.code === 10000) {
              message.success(' 装车成功：返回内容' + res.msg)
            } else {
              message.error(' 装车失败：返回内容' + res.msg)
            }
          })
          state.visiblemodal = false
          break
        case '4':
          carDepart({
            transportTruckId: recode.id,
            transportOrderId: recode.transportOrderId,
            transportOrderTruckId: recode.id
          }).then((res) => {
            if (res.code === 10000) {
              message.success(' 发车成功：返回内容' + res.msg)
            } else {
              message.error(' 发车失败：返回内容' + res.msg)
            }
          })
          break
        case '5':
          carTransfer({
            transportTruckId: recode.id,
            transportOrderId: recode.transportOrderId,
            transportOrderTruckId: recode.id
          }).then((res) => {
            if (res.code === 10000) {
              message.success(' 中转成功：返回内容' + res.msg)
            } else {
              message.error(' 中转失败：返回内容' + res.msg)
            }
          })
          break
        case '6':
          carArrive({
            transportTruckId: recode.id,
            transportOrderId: recode.transportOrderId,
            transportOrderTruckId: recode.id
          }).then((res) => {
            if (res.code === 10000) {
              message.success(' 到达成功：返回内容' + res.msg)
            } else {
              message.error(' 到达失败：返回内容' + res.msg)
            }
          })
          break
        case '7':
          carUnloadTruck({ ids: [recode.id] }).then((res) => {
            if (res.code === 10000) {
              message.success(' 卸板成功：返回内容' + res.msg)
            } else {
              message.error(' 卸板失败：返回内容' + res.msg)
            }
          })
          break
        case '8':
          carUnloadVehicle({ ids: [recode.transOrderVehicleId] }).then((res) => {
            if (res.code === 10000) {
              message.success(' 卸车成功：返回内容' + res.msg)
            } else {
              message.error('  卸车失败：返回内容' + res.msg)
            }
          })
          break
        case '9':
          state.carIMG.oncarid(recode.transOrderVehicleId)
          state.carIMG.onmodal(true)
          break
        case '10':
          carUnloadVehicle({ ids: state.datakey }).then((res) => {
            if (res.code === 10000) {
              message.success(' 卸车成功：返回内容' + res.msg)
            } else {
              message.error('  卸车失败：返回内容' + res.msg)
            }
          })
          break
        case '11':
          message.error(' 开发中')
          break
        default:
          message.success('出现错误' + type)
      }
      loadData()
    }
    const ontitle = (recode) => {
      state.title = recode
    }

    const ontransportOrderId = (recode) => {
      state.transportOrderId = recode
      loadData()
    }

    return {
      loadData,
      ...toRefs(state),
      ontitle,
      onclick,
      onClose,
      ontransportOrderId,
      hasSelected,
      onSelected,
      handleTableChange
    }
  }
}
</script>
